import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Form, ToggleButton } from "react-bootstrap";
import DatePicker from "./DatePicker";
import moment from "moment";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  getExpertbyId_WebService,
  AppointmentTimeService,
  AddAppointmentUserService,
  IndianpackagewithpriceService,
  getInstituteDiscountOODOService,
  AddUserPackageService,
  UserDetailsService,
  AddTransactionService,
  UpdateTransactionService,
  saveAppointmentOrder,
  SubscriptionDiscount,
} from "./core/_request";
import { useAuth } from "../auth";
import { getCountryCode, getCurrencyCode } from "./core/commonFun";
import { RescheduledService } from "./user/core/_request";
import { useSnackbar } from "notistack";
import { AppContext } from "../context";
import { Skeleton, Grid } from "@mui/material";

const BookAnAppointment = () => {
  const [loading, setLoading] = useState(false);
  const [slots, setSlots] = useState();
  const { currentUser, logout } = useAuth();
  const [data2, setData2] = useState();
  const [callTime, setCallTime] = useState(45);
  const [expertDetailsData, setExpertDetailsData] = useState();
  const [showCallTimeDuration, setShowCallTimeDuration] = useState(true);
  const navigate = useNavigate();
  let { id } = useParams();
  const search = useLocation().search;
  const countryCode = getCountryCode();
  const CurrencyCode = getCurrencyCode();
  const [calenderData, setCalenderData] = useState();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const bookAnAppointmentData = JSON.parse(
    localStorage.getItem("bookAnAppointmentData")
  );

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!currentUser) {
      // setShowLloginPopup(true);
      const currentUrl = encodeURIComponent(window.location.href);
      window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
      return;
    }
  }, [])

  useEffect(() => {
    // console.log("id", id)
    IndianpackagewithpriceService(id).then((resp) => {
      setData2(resp.data);
      saveAppointmentOrdersendToServer(resp.data);
    });
    getExpertbyId_WebService(id).then((resp) => {
      var data = resp?.data;
      if (countryCode == "IN")
        data["countryAmount"] = data?.Hourly_Consultaion_Fee_amount;
      else data["countryAmount"] = data?.USD_Amount;
      setExpertDetailsData(data);
      setIsLoading(false);
    });
    // console.log("setExpertDetailsData", expertDetailsData)
    binddata();
  }, []);

  useEffect(() => {
    let TimeDuration = new URLSearchParams(search).get("TimeDuration");
    if (TimeDuration) {
      setShowCallTimeDuration(false);
      setCallTime(TimeDuration);
    }
  }, []);

  // useEffect(() => {
  //     IndianpackagewithpriceService(id).then((resp) => setData2(resp.data));
  //     getExpertbyId_WebService(id).then(resp => {
  //         var data = resp.data;
  //         if (countryCode == "IN") data["countryAmount"] = data.Hourly_Consultaion_Fee_amount;
  //         else data["countryAmount"] = data.USD_Amount;
  //         setExpertDetailsData(data);
  //     });

  //     async function fetchMyAPI() {
  //         setLoading(true);
  //         var nLoading = true;
  //         for (let i = 0; i <= 7; i++) {
  //             var date = moment(new Date()).add("d", i);
  //             const resp = await AppointmentTimeService(id, date.format("YYYY/MM/DD"));
  //             if (resp.ResponseCode == 200) {
  //                 if (resp.data.filter(o => o.IsAvailable).length > 0) {
  //                     // const defaultValue = {
  //                     //     year: date.year(),
  //                     //     month: date.month() + 1,
  //                     //     day: date.date()
  //                     // };
  //                     setSelectedDate(date);
  //                     setSlots(resp.data.filter(o => o.IsAvailable));
  //                     setLoading(false);
  //                     nLoading = false;
  //                     break;
  //                 }
  //             }
  //             else if (resp.ResponseCode == 401) {
  //                 logout();
  //                 window.location.reload();
  //             }
  //         }

  //         if (nLoading) {
  //             //var date = moment(new Date());
  //             // const defaultValue = {
  //             //     year: date.year(),
  //             //     month: date.month() + 1,
  //             //     day: date.date()
  //             // };
  //             //console.log("defaultValue2", date)
  //             setSelectedDate(moment(new Date()));
  //             setLoading(false);
  //         }
  //     }
  //     if (!currentUser) {
  //         //window.location.href=`/login?retrunurl=/detail/${id}`;
  //         navigate(`/login?retrunurl=/detail/${id}`);
  //     }
  //     else {
  //         fetchMyAPI()

  //         //onDateChange(new Date());
  //     }
  // }, [])

  // const onDateChange = (val) => {
  //     // console.log("onDateChange", moment(val))
  //     setSelectedDate(moment(val))
  //     setSlots();
  //     setResponseMessage();
  //     setLoading(true);
  //     //var require={ExpertID:id,Days:moment(val).format("YYYY/MM/DD")};
  //     AppointmentTimeService(id, moment(val).format("YYYY/MM/DD")).then(resp => {
  //         setLoading(false);
  //         if (resp.ResponseCode == 200) {
  //             setSlots(resp.data.filter(o => o.IsAvailable));
  //         }

  //         setResponseMessage(resp.ResponseMessage);

  //     });
  // }

  const binddata = () => {
    var dates = [];
    for (var i = 0; i <= 15; i++) {
      var date = moment(new Date()).add("d", i);
      dates.push({
        date,
        avaliableSlots: "Loading",
        checked: bookAnAppointmentData
          ? moment(bookAnAppointmentData.selectedDate).format("DD MMM") ==
          moment(date).format("DD MMM")
          : i == 0,
      });
    }
    setCalenderData(dates);

    dates.forEach((element, index) => {
      AppointmentTimeService(
        id,
        moment(element.date).format("YYYY/MM/DD")
      ).then((resp) => {
        if (resp.ResponseCode == 200) {
          var length = resp?.data?.filter((o) => o.IsAvailable)?.length;
          element.avaliableSlots =
            length == 0 ? "Not Avaliable" : length + " Available";
          //element.checked = bookAnAppointmentData && bookAnAppointmentData.selectSlot.Time_Duration==element.Time_Duration
          setCalenderData([...dates]);
          if (
            bookAnAppointmentData &&
            moment(bookAnAppointmentData.selectedDate).format("DD MMM") ==
            moment(element.date).format("DD MMM")
          ) {
            //bookAnAppointmentData.selectSlot.Time_Duration==element.Time_Duration
            setSlots(
              resp?.data
                ?.filter((o) => o.IsAvailable)
                ?.map((o) => {
                  o["checked"] =
                    bookAnAppointmentData.selectSlot.Time_Duration ==
                    o.Time_Duration;
                  return o;
                })
            );
          } else if (!bookAnAppointmentData && index == 0) {
            setSlots(resp?.data?.filter((o) => o.IsAvailable));
          }
        }
      });
    });
  };

  const saveAppointmentOrdersendToServer = (data2s) => {
    var body = {
      ExpertId: id,
      App_Date: bookAnAppointmentData.selectedDate,
      App_Time: bookAnAppointmentData.selectSlot.Time_Duration,
      Session_Type: callTime,
      Amount: data2s.Amount,
    };
    saveAppointmentOrder(body).then((resp) => {
      // console.log("saveAppointmentOrder resp", resp)
      if (resp.data == "") {
      }
    });
  };

  const getSlotByDate = (element) => {
    setCalenderData(
      calenderData?.map((o) => {
        if (o.date == element.date) o.checked = true;
        else o.checked = false;
        return o;
      })
    );

    AppointmentTimeService(id, moment(element.date).format("YYYY/MM/DD")).then(
      (resp) => {
        if (resp.ResponseCode == 200) {
          setSlots(resp.data.filter((o) => o.IsAvailable));
        }
      }
    );
  };

  const onCheckout = () => {
    if (!currentUser) {
      // setShowLloginPopup(true);
      const currentUrl = encodeURIComponent(window.location.href);
      window.location.href = `https://accounts.unitedwecare.com/?redirect=${currentUrl}`;
      return;
    }
    var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
    var user_email = JSON.parse(localStorage.getItem("profile"))?.Emailid;
    var body = {
      params: {
        userId: user_id,
        email: user_email,
        expert_id: id,
      },
    };
    SubscriptionDiscount(body).then((resp) => {
      debugger
      // if (resp?.discountPercentage === 0) {
      //   navigate(`/payment-order-preview/${id}`);
      // } else if (resp?.discountPercentage > 0) {
        var selectSlot = slots?.filter((o) => o.checked);
        const selecteDate = calenderData?.find((o) => o.checked)?.date
        if (selectSlot?.length == 0) {
          enqueueSnackbar("Please select date and time slot!", { variant: "warning" });
          return false;
        }
        setLoading(true);
        debugger
        var book_App_datat = JSON.stringify({
          selectedDate: calenderData?.find((o) => o.checked)?.date,
          selectSlot: slots?.find((o) => o.checked),
          callTime: callTime,
          data2: data2,
        });
        localStorage.setItem("bookAnAppointmentData", book_App_datat);

        localStorage.setItem("dispr", resp?.discountPercentage);

        const AppointmentId = new URLSearchParams(search).get("AppointmentId");

        if (AppointmentId)
          navigate(
            `/payment-order-preview/${id}?AppointmentId=${AppointmentId}`,
            {}
          );
        else navigate(`/payment-order-preview/${id}`);
      // }
    });
  };

  console.log("data2", data2);
  return (
    expertDetailsData && expertDetailsData?.IsAdminApproval === false ? (
      <div className="container my-3 my-md-5">
        <div className="d-flex align-items-center flex-wrap-reverse justify-content-md-between justify-content-center py-md-5 w-100">
          <div className="col-md-6 d-flex flex-column justify-content-between gap-1 gap-md-4 align-items-center align-items-md-start">
            <h5
              className="text-center text-md-start notFound-heading"
              style={{
                color: "#101828",
                fontFamily: "Roboto",
                fontSize: "60px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "66px",
                margin: 0
              }}
            >
              We're Sorry!
            </h5>
            <p
              className="text-center text-md-start notFound-text font-roboto"
              style={{
                color: "#737373",
                fontFamily: "Roboto !important",
                fontSize: "26px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "36px",
                letterSpacing: "-0.26px"
              }}
            >
              The expert you're looking for is no longer available on our platform. We're committed to connecting you with the guidance and support you need. Please explore other best-in-class certified professionals available on our platform. Thank you for choosing us to support your wellness journey.
            </p>
            <button
              onClick={() => navigate('/')}
              className="border-0 px-md-4 py-md-3 py-2 mt-3 notFound-btn col-12 col-md-6 mb-5"
              style={{
                borderRadius: "12px",
                background: "#73589B",
                color: "#fff",
                fontFamily: "Roboto",
                fontSize: "28px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "32px",
              }}
            >
              Explore Other Experts
            </button>
          </div>
          <div className="col-md-6">
            <img src="/assets/img/kareify/image(2).png" className="mx-auto d-block notFound-img " alt="" />
          </div>
        </div>
      </div>
    ) : (
      <div
        className="pb-5"
        style={{ background: "#F7F2FF", padding: 0, margin: 0 }}
      >
        <div className="container">
          <div className="py-md-5 py-3">
            {isLoading ? (
              <div>
                <Skeleton
                  variant="rounded"
                  width="20%"
                  height={30}
                  className="rounded-3 mx-auto"
                />
              </div>
            ) : (
              <h6 className="font-inter text-black font-w600 fs-4 text-center">
                Book a Session
              </h6>
            )}
          </div>
          <div
            className="bg-white"
            style={{
              background: "#FFFFFF",
              boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12)",
              borderRadius: "12px",
            }}
          >
            <div className="py-3 mx-auto px-2 px-md-5 row gap-md-5">
              <div className="">
                {isLoading ? (
                  <>
                    <Skeleton
                      variant="rounded"
                      width="20%"
                      height={25}
                      className="rounded-3 my-3"
                    />
                    <Grid container spacing={2}>
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(7, 1fr)",
                          gap: "15px",
                          width: "100%",
                          padding: "20px",
                        }}
                      >
                        {Array.from(new Array(7)).map((_, index) => (
                          <Grid item xs={12} sm={6} md={4} lg={2} key={index} sx={{ minWidth: "100%" }}>
                            <Skeleton
                              variant="rounded"
                              width="100%"
                              height={35}
                            />
                          </Grid>
                        ))}
                      </div>
                    </Grid>
                  </>
                ) : (
                  <>
                    <h5 className="font-inter text-black font-w500 fs-6">
                      Choose Date
                    </h5>
                    <ButtonGroup className="d-flex flex-row gap-3 col-12 my-2 overflowX-scroll py-4">
                      {calenderData?.map(
                        (item, index) =>
                          item.avaliableSlots != "Not Avaliable" && (
                            <ToggleButton
                              key={index}
                              onClick={() => getSlotByDate(item)}
                              type="radio"
                              name="radio"
                              className="date-availability rounded-2 col-2 px-1 py-1 ms-1 "
                              style={{
                                background: item.checked
                                  ? "#CCB6EC"
                                  : "#fff",
                                color: "#000",
                                border: "1px solid #CCB6EC",
                                minWidth: "110px",
                                maxWidth: "150px",
                              }}
                            >
                              <p className="font-inter fs-6 text-center">
                                {moment(item.date).format("DD MMM") ==
                                  moment().format("DD MMM")
                                  ? "Today"
                                  : moment(item.date).format("DD MMM")}
                              </p>
                              <p className="font-inter fs-6 text-center">
                                {item?.avaliableSlots}
                              </p>
                            </ToggleButton>
                          )
                      )}
                    </ButtonGroup>
                  </>
                )}
              </div>
            </div>
            {isLoading ? (
              <div style={{ padding: "16px 48px" }}>
                <Skeleton
                  variant="rounded"
                  width="20%"
                  height="20px"
                  className="rounded-3 my-3"
                />
                <Grid container spacing={2}>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "repeat(7, 1fr)",
                      width: "100%",
                      gap: "10px",
                      padding: "15px",
                    }}
                  >
                    {Array.from(new Array(35)).map((_, index) => (
                      <Grid item key={index} sx={{ minWidth: "100%" }}>
                        <Skeleton variant="rounded" width="100%" height={50} />
                      </Grid>
                    ))}
                  </div>
                </Grid>
              </div>
            ) : slots && slots?.length == 0 ? (
              <div className="py-3 mx-auto px-2 px-md-5 row gap-md-2">
                <h5 className="font-inter text-black font-w500 fs-6">
                  Available Time Slots
                </h5>
                <p className="font-inter fs-6 text-black font-w400 ms-3">
                  No slot Available {slots?.[0]?.TimeZone && slots?.[0]?.TimeZone}
                </p>
              </div>
            ) : (
              <div className="py-3 mx-auto px-2 px-md-5 row gap-md-2">
                <h5 className="font-inter text-black font-w500 fs-6">
                  Available Time Slots ({slots?.[0]?.TimeZone})
                </h5>
                <div>
                  <ButtonGroup className="row gap-3 col-12 mx-1 my-2">
                    {slots?.map((item, index) => (
                      <ToggleButton
                        key={index}
                        onClick={() =>
                          setSlots(
                            slots?.map((o) => {
                              if (o.ID == item.ID) o.checked = true;
                              else o.checked = false;
                              return o;
                            })
                          )
                        }
                        type="radio"
                        name="radio"
                        className="time-availability rounded-2 py-2 px-0 col-3 d-block"
                        style={{
                          background: item.checked ? "#CCB6EC" : "#F2F4F7",
                          color: "#000",
                          border: "1px solid #CCB6EC",
                          minWidth: "110px",
                          maxWidth: "150px",
                        }}
                      >
                        <p>{item.Time_Duration}</p>
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                </div>
              </div>
            )}
            {showCallTimeDuration && (
              <div className="py-3 mx-auto px-2 px-md-5 row gap-md-2">
                <div className="">
                  {isLoading ? (
                    <>
                      <Skeleton
                        variant="rounded"
                        width="20%"
                        height="20px"
                        className="rounded-3 my-3"
                      />
                      <Grid container spacing={2}>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2, 1fr)",
                            width: "100%",
                            gap: "10px",
                            padding: "15px",
                          }}
                        >
                          {Array.from(new Array(2)).map((_, index) => (
                            <Grid item key={index} sx={{ minWidth: "100%" }}>
                              <div className="py-2 my-3 px-3 col-12 col-md-5">
                                <Skeleton
                                  variant="rounded"
                                  width="100%"
                                  height={50}
                                />
                              </div>
                            </Grid>
                          ))}
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <>
                      {data2?.AppType != "Psychiatrist" && (
                        <h5 className="font-inter text-black font-w500 fs-6">
                          Session Duration
                        </h5>
                      )}
                      {data2 && (
                        <ButtonGroup className="d-flex flex-wrap flex-md-row gap-2">
                          <ToggleButton
                            defaultChecked
                            type="radio"
                            name="radio"
                            className="py-2 my-3 px-3 col-12 col-md-5"
                            value={callTime}
                            style={
                              callTime === 45
                                ? {
                                  background: "#CCB6EC",
                                  color: "black",
                                  borderRadius: "100px",
                                  border: "none",
                                }
                                : {
                                  background: "#F2F4F7",
                                  color: "black",
                                  borderRadius: "100px",
                                  border: "none",
                                }
                            }
                            onClick={() => setCallTime(45)}
                          >
                            45 min
                          </ToggleButton>
                        </ButtonGroup>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            <div className="py-3 mx-auto px-2 px-md-5 row gap-md-2">
              {isLoading ? (
                <div>
                  <Skeleton variant="rounded" width="20%" height="25px" className="rounded-3 my-2" />
                  <Skeleton variant="rounded" width="100%" height={150} className="rounded-2" />
                </div>
              ) : (
                <>
                  <h5 className="font-inter text-black font-w500 fs-6">
                    Describe Your Emotional Wellness Challenges In Words
                  </h5>
                  <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                    <Form.Control
                      as="textarea"
                      style={{ height: "150px" }}
                      placeholder="Share your concerns & a brief about your condition here. This will give the expert an understanding of the challenges you are facing and the help you seek."
                      rows={3}
                    />
                  </Form.Group>
                </>
              )}
            </div>
            <div className="d-flex flex-row justify-content-center py-3 px-4 gap-3">
              {isLoading ? (
                <div>
                  <Skeleton variant="rounded" width="180px" height="48px" />
                </div>
              ) : (
                <Button
                  id="appointment_book_now"
                  className="book-now-a-professional col-12 col-md-3 px-5 border-0 font-w600 font-inter fs-6 lh-base text-white"
                  style={{
                    background: "#73589B",
                    borderRadius: "10px",
                    height: "48px",
                  }}
                  onClick={onCheckout}
                >
                  Book Now
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );

};

export default BookAnAppointment;